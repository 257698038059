import React, { useState, useEffect, useContext } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import firebase from "../../firebase";
import { AuthContext } from "../AuthProvider";
import { stats, modes, paymentMethods, deliveryOptions, paymentUtilities } from "../../enums";
import Input from "../elements/Input";
import Select from "../elements/Select";
import PhoneInput from "../elements/PhoneInput";
import Textarea from '../elements/Textarea';

const db = firebase.firestore();

function Edit() {
    const {id} = useParams<{id: string}>();
    const [trackId, setTrackId] = useState<string>(id);
    const [status, setStatus] = useState<string>(Object.keys(stats)[0]);
    const [senderName, setSenderName] = useState("");
    const [senderPhone, setSenderPhone] = useState("");
    const [senderAddress, setSenderAddress] = useState("");
    const [receiverName, setReceiverName] = useState("");
    const [receiverPhone, setReceiverPhone] = useState("");
    const [receiverAddress, setReceiverAddress] = useState("");
    const [weight, setWeight] = useState();
    const [price, setPrice] = useState();
    const [paymentMethod, setPaymentMethod] = useState("");
    const [hasDelivery, setHasDelivery] = useState("");
    const [mode, setMode] = useState("");
    const [departTime, setDepartTime] = useState("");
    const [desc, setDesc] = useState("");
    const [value, setValue] = useState("");
    const [postfee, setPostfee] = useState(0);
    const [quantity, setQuantity] = useState(0);
    const [notif, setNotif] = useState("");
    const [paymentUtility, setPaymentUtility] = useState("");
    const { currentUser } = useContext(AuthContext);
    const [docRef] = useState<firebase.firestore.DocumentReference>(db.collection("packages").doc(id));
    const history = useHistory();

    useEffect(() => {
        docRef.get().then((doc) => {
            if (doc.exists) {
                let data = doc.data();
                setTrackId(doc.id);
                setSenderName(data?.senderName);
                setSenderPhone(data?.senderPhone);
                setSenderAddress(data?.senderAddress);
                setReceiverName(data?.receiverName);
                setReceiverPhone(data?.receiverPhone);
                setReceiverAddress(data?.receiverAddress);
                setWeight(data?.weight);
                setPrice(data?.price);
                setPaymentMethod(data?.paymentMethod);
                setHasDelivery(data?.hasDelivery);
                setMode(data?.mode || Object.keys(modes)[0]);
                setStatus(data?.status || Object.keys(stats)[0]);
                setDepartTime(data?.departTime);
                setDesc(data?.desc);
                setValue(data?.value);
                setPostfee(data?.postfee);
                setQuantity(data?.quantity);
                setNotif(data?.notif);
                setPaymentUtility(data?.paymentUtility);
            }
        }).catch(console.error);
    }, [id, docRef]);

    const update = () => {
        docRef.update({
            status,
            senderName,
            senderPhone,
            senderAddress,
            receiverName,
            receiverPhone,
            receiverAddress,
            weight,
            price,
            paymentMethod,
            hasDelivery,
            mode,
            departTime,
            desc,
            value,
            postfee,
            quantity,
            notif,
            paymentUtility,
            updatedBy: currentUser?.uid,
        }).then(() => {
            history.push(`/packages/${id}`);
        }).catch(console.error);
    }

    return (
        <>
            <small>Захиалгын дугаар:</small>
            <h3>{trackId}</h3>

            {currentUser?.role === "admin" && <>
                <div className="row">
                    <div className="col">
                        <Input state={senderName} setState={setSenderName} label="Илгээгчийн нэр" />
                    </div>
                    <div className="col">
                        <PhoneInput state={senderPhone} setState={setSenderPhone} label="Илгээгчийн утас" />
                    </div>
                </div>
                <Textarea state={senderAddress} setState={setSenderAddress} label="Илгээгчийн хаяг" />
                <div className="row">
                    <div className="col">
                        <Input state={receiverName} setState={setReceiverName} label="Хүлээн авагчийн нэр" />
                    </div>
                    <div className="col">
                        <PhoneInput state={receiverPhone} setState={setReceiverPhone} label="Хүлээн авагчийн утас" />
                    </div>
                </div>
                <Textarea state={receiverAddress} setState={setReceiverAddress} label="Хүлээн авагчийн хаяг" />
                <div className="row">
                    <div className="col">
                        <Input state={weight} setState={setWeight} label="Жин" type="number" append="кг" />
                    </div>
                    <div className="col">
                        <Input state={price} setState={setPrice} label="Үнийн дүн" type="number" prepend="₩" />
                    </div>
                    <div className="col">
                        <Input state={departTime} setState={setDepartTime} label="Гарах огноо" type="date" />
                    </div>
                </div>
            </>}
            {["admin", "worker"].indexOf(currentUser?.role) !== -1 && <>
                <div className="row">
                    {["admin", "worker"].indexOf(currentUser?.role) !== -1 && <>
                        <div className="col">
                            <Select state={paymentMethod} setState={setPaymentMethod} label="Төлбөрийн мэдээлэл" options={paymentMethods} />
                        </div>
                    </>}
                    {["admin"].indexOf(currentUser?.role) !== -1 && <>
                        <div className="col">
                            <Select state={paymentUtility} setState={setPaymentUtility} label="Төлбөрийн хэрэгсэл" options={paymentUtilities} />
                        </div>
                        <div className="col">
                            <Select state={hasDelivery} setState={setHasDelivery} label="Монголд хүргэлттэй эсэх" options={deliveryOptions} />
                        </div>
                    </>}
                </div>
            </>}
            {currentUser?.role === "admin" && <>
                <div className="row">
                    <div className="col">
                        <Input state={desc} setState={setDesc} label="Ачааны мэдээлэл" />
                    </div>
                    <div className="col">
                        <Input state={value} setState={setValue} label="Ачааны үнэлгээ" />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <Input type="number" state={quantity} setState={setQuantity} label="Ачааны тоо хэмжээ" />
                    </div>
                    <div className="col">
                        <Input type="nubmer" state={postfee} setState={setPostfee} label="Шуудангийн төлбөр" />
                    </div>
                </div>
                <Textarea state={notif} setState={setNotif} label="Тээврийн мэдээлэл" />
                <Select state={mode} setState={setMode} label="Ангилал" options={modes} />
            </>}

            {["admin", "delivery"].indexOf(currentUser?.role) !== -1 && <>
                <Select state={status} setState={setStatus} label="Төлөв" options={currentUser?.role === "admin" ? stats : {delivered: stats["delivered"], [status]: stats[status]}} />
            </>}

            <Link className="btn btn-light" to={`/packages/${id}`}>
                <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-caret-left" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" d="M10 12.796L4.519 8 10 3.204v9.592zm-.659.753l-5.48-4.796a1 1 0 0 1 0-1.506l5.48-4.796A1 1 0 0 1 11 3.204v9.592a1 1 0 0 1-1.659.753z" />
                </svg>&nbsp;
                Буцах
            </Link>
            <button className="btn btn-primary float-right" onClick={update}>
                <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-pencil" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5L13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175l-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                </svg>&nbsp;
                Шинэчлэх
            </button>
        </>
    );
}

export default Edit;