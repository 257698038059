import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

function Home() {
    const [id, setId] = useState("");
    const history = useHistory();

    return (
        <>
            <div style={{
                position: "absolute",
                height: "100%",
                width: "100%",
                top: 0,
                left: 0,
                zIndex: -1
            }}>
                <div className="carousel carousel-fade slide h-100" data-ride="carousel">
                    <div className="carousel-inner h-100">
                        <div className="carousel-item active h-100" style={{backgroundImage: "url(bg0.jpg)", backgroundSize: "cover", backgroundPosition: "center"}}></div>
                        <div className="carousel-item h-100" style={{backgroundImage: "url(bg1.jpg)", backgroundSize: "cover", backgroundPosition: "center"}}></div>
                        <div className="carousel-item h-100" style={{backgroundImage: "url(bg2.jpg)", backgroundSize: "cover", backgroundPosition: "center"}}></div>
                    </div>
                </div>
            </div>
            <br className="d-none d-md-block" />
            <br className="d-none d-md-block" />
            <br className="d-none d-md-block" />
            <br />
            <h1 className="text-center text-white d-none d-md-block">Бараа шалгах</h1>

            <div className="input-group input-group-lg mt-md-5">
                <input type="text" className="form-control" placeholder="Ачааны код" value={id} onChange={(e) => setId(e.target.value)} style={{borderRadius: "24px 0 0 24px", border: "none"}} />
                <div className="input-group-append">
                    <button className="btn btn-primary" type="button" onClick={() => history.push(`/track/${id}`)} style={{borderRadius: "0 24px 24px 0"}}>
                        &nbsp;
                        &nbsp;
                        <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-search" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" d="M10.442 10.442a1 1 0 0 1 1.415 0l3.85 3.85a1 1 0 0 1-1.414 1.415l-3.85-3.85a1 1 0 0 1 0-1.415z" />
                            <path fillRule="evenodd" d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z" />
                        </svg>
                        &nbsp;
                        &nbsp;
                    </button>
                </div>
            </div>
            <br />
            <br />
            <br />
            <br />
            <br className="d-none d-md-block" />
            <br className="d-none d-md-block" />
            <br className="d-none d-md-block" />
            <br className="d-none d-md-block" />
            <br className="d-none d-md-block" />
            {/* <div className="row justify-content-center">
                <div className="col-12 col-md-8 col-lg-6">
                    <img src="bnnr.jpeg" className="img-fluid" alt="ad" />
                </div>
            </div> */}
            <br className="d-block d-md-none" />
            <br className="d-block d-md-none" />
            <br className="d-block d-md-none" />
            <br className="d-block d-md-none" />
        </>
    );
}

export default Home;
