import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import firebase from "../firebase";
import { stats, modes, paymentMethods, deliveryOptions } from "../enums";
import { phoneNumberStyler } from '../helpers';

function Track() {
    const {id} = useParams<{id: string}>();
    const [trackId] = useState<string>(id);
    const [status, setStatus] = useState<string>("");
    const [senderPhone, setSenderPhone] = useState("");
    const [receiverPhone, setReceiverPhone] = useState("");
    const [weight, setWeight] = useState();
    const [paymentMethod, setPaymentMethod] = useState("");
    const [hasDelivery, setHasDelivery] = useState("");
    const [arrivalDate, setArrivalDate] = useState("");
    const [mode, setMode] = useState("");
    const [loading, setLoading] = useState(true);
    const [desc, setDesc] = useState("");
    const [value, setValue] = useState("");

    useEffect(() => {
        firebase.functions().httpsCallable('getPackage')({id}).then((data) => {
            if (data.data) {
                setSenderPhone(data.data?.senderPhone);
                setReceiverPhone(data.data?.receiverPhone);
                setWeight(data.data?.weight);
                setPaymentMethod(data.data?.paymentMethod);
                setHasDelivery(data.data?.hasDelivery);
                setMode(data.data?.mode || Object.keys(modes)[0]);
                setStatus(data.data?.status || Object.keys(stats)[0]);
                let week = 7*24*60*60*1000;
                let duration = data.data?.mode === "express" ? week : week*2;
                let departAt = new Date(data.data?.departTime);
                let arrive = new Date(departAt.getTime() + duration);
                setArrivalDate(arrive.toJSON().substr(0, 10));
                setDesc(data.data?.desc);
                setValue(data.data?.value);
            }
            setLoading(false);
        }).catch(console.error);
    }, [id]);

    return (
        <>
            <small>Захиалгын дугаар:</small>
            <h3>{trackId}</h3>

            {loading ? <>
                <div className="text-center">
                    <div className="spinner-border text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            </> : <>
                {status ? <>
                    <table className="table table-bordered">
                        <tbody>
                            <tr>
                                <td className="table-active">Төлөв</td>
                                <td>{stats[status]}</td>
                            </tr>
                            <tr>
                                <td className="table-active">Илгээгчийн утас</td>
                                <td dangerouslySetInnerHTML={{__html: phoneNumberStyler(senderPhone)}}></td>
                            </tr>
                            <tr>
                                <td className="table-active">Хүлээн авагчийн утас</td>
                                <td dangerouslySetInnerHTML={{__html: phoneNumberStyler(receiverPhone)}}></td>
                            </tr>
                            <tr>
                                <td className="table-active">Жин</td>
                                <td>{weight}<span className="text-muted">кг</span></td>
                            </tr>
                            <tr>
                                <td className="table-active">Төлбөрийн мэдээлэл</td>
                                <td>{paymentMethods[paymentMethod]}</td>
                            </tr>
                            <tr>
                                <td className="table-active">Монголд хүргэлттэй эсэх</td>
                                <td>{deliveryOptions[hasDelivery]}</td>
                            </tr>
                            <tr>
                                <td className="table-active">Ангилал</td>
                                <td>{modes[mode]}</td>
                            </tr>
                            <tr>
                                <td className="table-active">Буух огноо</td>
                                <td>{arrivalDate}</td>
                            </tr>
                            <tr>
                                <td className="table-active">Ачааны мэдээлэл:</td>
                                <td>{desc}</td>
                            </tr>
                            <tr>
                                <td className="table-active">Ачааны үнэлгээ:</td>
                                <td>{value}</td>
                            </tr>
                        </tbody>
                    </table>
                </> : <>
                    <div className="alert alert-danger" role="alert">
                        ТАНЫ АЧААНЫ КОД БУРУУ БАЙНА ТА АЧААНЫ КОДОО ДАХИН ШАЛГАНА УУ
                    </div>
                </>}
            </>}

            <small className="d-block text-right">
                <strong>Солонгост холбогдох дугаар:</strong> 010-5833-1981<br />
                <strong>Монголд холбогдох дугаар:</strong> 8002-1111
            </small>

            <Link className="btn btn-light" to="/">
                <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-caret-left" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" d="M10 12.796L4.519 8 10 3.204v9.592zm-.659.753l-5.48-4.796a1 1 0 0 1 0-1.506l5.48-4.796A1 1 0 0 1 11 3.204v9.592a1 1 0 0 1-1.659.753z" />
                </svg>&nbsp;
                Буцах
            </Link>
        </>
    );
}

export default Track;