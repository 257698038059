import React, {  } from 'react';
import { Link } from 'react-router-dom';
import { stats, modes } from "../../enums";
import { phoneNumberStyler } from "../../helpers";

function ListItem(props: any) {
    const { pack } = props;

    return (
        <li
            className={`list-group-item position-relative`}
            style={{
                borderLeftWidth: "5px",
                borderLeftColor: pack.mode === "express" ? "rgba(0,123,255,.5)" : "",
                lineHeight: 1
            }}
            key={pack.id}
        >
            <div className="row position-static">
                <div className="col position-static">
                    <Link className="stretched-link" to={`/packages/${pack.id}`}>
                        <code>{pack.id}</code>
                    </Link>
                    <div><small>{modes[pack.mode]}</small></div>
                </div>
                <div className="col">
                    <small>
                        <span className="text-muted">Илгээгчийн дугаар:</span>
                        <div dangerouslySetInnerHTML={{__html: phoneNumberStyler(pack.senderPhone)}}></div>
                    </small>
                </div>
                <div className="col">
                    <small>
                        <span className="text-muted">Хүлээн авагчийн дугаар:</span>
                        <div dangerouslySetInnerHTML={{__html: phoneNumberStyler(pack.receiverPhone)}}></div>
                    </small>
                </div>
                {pack.status &&
                    <div className="col">
                        <small>
                            <span className="text-muted">Төлөв:</span>
                            <div>{stats[pack.status]}</div>
                        </small>
                    </div>
                }
            </div>
        </li>
    );
}

export default ListItem;