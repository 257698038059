import React from 'react';

function Footer() {
  return (
    <footer className="mt-5 pt-4 pb-1 text-white text-center text-small" style={{backgroundColor: "rgba(0,0,0,.3)"}}>
      <ul className="list-inline">
        <li className="list-inline-item">
          <a href="https://www.facebook.com/bilegcargo/">
            <svg style={{width: "24px", height: "24px"}} viewBox="0 0 24 24">
              <path fill="white" d="M12 2.04C6.5 2.04 2 6.53 2 12.06C2 17.06 5.66 21.21 10.44 21.96V14.96H7.9V12.06H10.44V9.85C10.44 7.34 11.93 5.96 14.22 5.96C15.31 5.96 16.45 6.15 16.45 6.15V8.62H15.19C13.95 8.62 13.56 9.39 13.56 10.18V12.06H16.34L15.89 14.96H13.56V21.96A10 10 0 0 0 22 12.06C22 6.53 17.5 2.04 12 2.04Z" />
            </svg>
          </a>
        </li>
        <li className="list-inline-item">
          <a href="tel:+8201058331981">
            <svg style={{width: "24px", height: "24px"}} viewBox="0 0 24 24">
              <path fill="white" d="M6.62,10.79C8.06,13.62 10.38,15.94 13.21,17.38L15.41,15.18C15.69,14.9 16.08,14.82 16.43,14.93C17.55,15.3 18.75,15.5 20,15.5A1,1 0 0,1 21,16.5V20A1,1 0 0,1 20,21A17,17 0 0,1 3,4A1,1 0 0,1 4,3H7.5A1,1 0 0,1 8.5,4C8.5,5.25 8.7,6.45 9.07,7.57C9.18,7.92 9.1,8.31 8.82,8.59L6.62,10.79Z" />
            </svg>
          </a>
        </li>
      </ul>
    </footer>
  );
}

export default Footer;