import React, { useState, useEffect } from 'react';
import firebase from "../firebase";

export const AuthContext: any = React.createContext(null);

export const AuthProvider = (props: any) => {
  const { children } = props;
  const [currentUser, setCurrentUser] = useState<any>(undefined);

  useEffect(() => {
    firebase.auth().onAuthStateChanged((u) => {
      setCurrentUser(u);
      firebase.firestore().doc(`users/${u?.uid}`).get().then((doc) => {
        if (doc.exists) {
          setCurrentUser({
            uid: u?.uid,
            phoneNumber: u?.phoneNumber,
            role: doc.data()?.role
          });
        }
      });
    });
  }, []);

  return (
    <AuthContext.Provider value={{currentUser}}>
      {children}
    </AuthContext.Provider>
  );
}