import React, { useState } from 'react';

const Textarea = (props: any) => {
    const [id] = useState(Math.random().toString().split(".")[1]);

    return (
        <div className="form-group">
            <label htmlFor={id}>{props.label}:</label>
            <textarea className="form-control" id={id} defaultValue={props.state} onChange={(e) => props.setState(e.target.value)} />
        </div>
    );
}

export default Textarea;