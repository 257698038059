import firebase from 'firebase/app';
import "firebase/analytics";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";

const config = {
    apiKey: "AIzaSyCGZ7A866tIgDeI2hrSoAXlXl0z8nTEY-8",
    authDomain: "bileg-cargo.firebaseapp.com",
    projectId: "bileg-cargo",
    appId: "1:880664032763:web:e492968aa23b76f50f475e",
    measurementId: "G-JBXPQSHQT9"
};

firebase.initializeApp(config);
firebase.analytics();
firebase.auth();
firebase.firestore();
firebase.functions();

export default firebase;