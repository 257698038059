export let stats: any = {
    received: "Ачааг хүлээн авсан",
    departed: "Инчоны боомтоос тээвэрлэлтэнд гарлаа",
    arrivedChina: "Хятад",
    arrived: "Улаанбаатар",
    delivered: "Ачааг хүлээлгэж өгсөн",
}

export let modes: any = {
    normal: "Энгийн",
    air: "Агаарын ачаа",
    express: "Экспресс",
}

export let paymentMethods: any = {
    mustPaid: "Монголд төлөгдөнө",
    paid: "Солонгост төлөгдсөн",
    waiting: "Хүлээгдэж байна",
}

export let deliveryOptions: any = {
    uncertain: "Тодорхойгүй",
    delivery: "Монголд хүргэлттэй",
    noDelivery: "Монголд хүргэлтгүй",
}

export let roles: any = {
    admin: "Админ",
    worker: "Захиалгын ажилтан",
    delivery: "Ачаа хүлээлгэн өгөгч",
    reporter: "Тайлагнагч"
}

export let paymentUtilities: any = {
    cash: "Бэлэн",
    transaction: "Бэлэн бус",
}
