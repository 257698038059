import React, { useState, useEffect, useContext, useRef } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import firebase from "../../firebase";
import { AuthContext } from "../AuthProvider";
import { stats, modes, paymentMethods, deliveryOptions, paymentUtilities } from "../../enums";
import { phoneNumberStyler } from "../../helpers";

const db = firebase.firestore();

function View() {
    const {id} = useParams<{id: string}>();
    const [trackId, setTrackId] = useState<string>(id);
    const [status, setStatus] = useState<string>(Object.keys(stats)[0]);
    const [senderName, setSenderName] = useState("");
    const [senderPhone, setSenderPhone] = useState("");
    const [senderAddress, setSenderAddress] = useState("");
    const [receiverName, setReceiverName] = useState("");
    const [receiverPhone, setReceiverPhone] = useState("");
    const [receiverAddress, setReceiverAddress] = useState("");
    const [weight, setWeight] = useState();
    const [price, setPrice] = useState();
    const [paymentMethod, setPaymentMethod] = useState("");
    const [hasDelivery, setHasDelivery] = useState("");
    const [mode, setMode] = useState("");
    const [arrivalDate, setArrivalDate] = useState("");
    const [docRef] = useState<firebase.firestore.DocumentReference>(db.collection("packages").doc(id));
    const [departTime, setDepartTime] = useState("");
    const [createdBy, setCreatedBy] = useState("");
    const [desc, setDesc] = useState("");
    const [value, setValue] = useState("");
    const [postfee, setPostfee] = useState(0);
    const [quantity, setQuantity] = useState(0);
    const [notif, setNotif] = useState("");
    const [paymentUtility, setPaymentUtility] = useState("");
    const history = useHistory();
    const template = useRef(null);
    const { currentUser } = useContext(AuthContext);

    useEffect(() => {
        docRef.get().then((doc) => {
            if (doc.exists) {
                let data = doc.data();
                setTrackId(doc.id);
                setSenderName(data?.senderName);
                setSenderPhone(data?.senderPhone);
                setSenderAddress(data?.senderAddress);
                setReceiverName(data?.receiverName);
                setReceiverPhone(data?.receiverPhone);
                setReceiverAddress(data?.receiverAddress);
                setWeight(data?.weight);
                setPrice(data?.price);
                setPaymentMethod(data?.paymentMethod);
                setHasDelivery(data?.hasDelivery);
                setMode(data?.mode || Object.keys(modes)[0]);
                setStatus(data?.status || Object.keys(stats)[0]);
                setDepartTime(data?.departTime);
                setCreatedBy(data?.createdBy);
                setDesc(data?.desc);
                setValue(data?.value);
                setPostfee(data?.postfee);
                setQuantity(data?.quantity);
                setNotif(data?.notif);
                setPaymentUtility(data?.paymentUtility);
                let week = 7*24*60*60*1000;
                let duration = data?.mode === "express" ? week : week*2;
                let departAt = new Date(data?.departTime);
                let arrive = new Date(departAt.getTime() + duration);
                setArrivalDate(arrive.toJSON().substr(0, 10));
            }
        }).catch(console.error);
    }, [id, docRef]);

    const remove = () => {
        // eslint-disable-next-line no-restricted-globals
        if (confirm("Та устгахдаа итгэлтэй байна уу?")) {
            docRef.delete().then(() => {
                history.push("/packages");
            }).catch(console.error);
        }
    }

    const printTemplate = () => {
        const w = window.open("", "", "left=0,top=0,toolbar=0,scrollbars=0,status=0");
        const innerHTML = `
            <html>
                <head>
                    <link href="https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@700&family=Roboto:wght@400;700&display=swap" rel="stylesheet" rel="preload">
                    <style>
                            @page {
                                margin: 0;
                                /*size: 100mm 200mm;*/
                            }
                            body {
                                margin: 0;
                            }
                            table#main {
                                -webkit-transform: rotate(-90deg) translate(-50mm, -50mm); /* Safari and Chrome */
                                -moz-transform: rotate(-90deg) translate(-50mm, -50mm);   /* Firefox */
                                -ms-transform: rotate(-90deg) translate(-50mm, -50mm);   /* IE 9 */
                                -o-transform: rotate(-90deg) translate(-50mm, -50mm);   /* Opera */
                                transform: rotate(-90deg) translate(-50mm, -50mm);
                            }
                    </style>
                </head>
                <body>
                    <table id="main" border="0" style="width:200mm;height:100mm;">
                        <tr>
                            <td style="vertical-align:top;">
                                <table border="0" style="width:82mm;padding-left:8mm;">
                                    <tr>
                                        <td style="height:30mm;"></td>
                                    </tr>
                                    <tr>
                                        <td style="height:19mm;padding-top:5mm;">${trackId}</td>
                                    </tr>
                                    <tr>
                                        <td style="height:52px;padding-top:20px;>${desc}</td>
                                    </tr>
                                    <tr>
                                        <td style="height:50px;padding-top:15px;">${senderPhone}</td>
                                    </tr>
                                    <tr>
                                        <td style="height:100%;padding-top:20px;">${senderAddress}</td>
                                    </tr>
                                </table>
                            </td>
                            <td style="vertical-align:top;">
                                <table border="0" style="padding-left:72px;">
                                    <tr>
                                        <td style="height:90px;padding-top:42px;">${trackId}</td>
                                    </tr>
                                    <tr>
                                        <td style="height:51px;padding-top:20px;">${receiverName}</td>
                                    </tr>
                                    <tr>
                                        <td style="height:51px;padding-top:20px;">${receiverPhone}</td>
                                    </tr>
                                    <tr>
                                        <td style="height:100%;padding-top:20px;">${receiverAddress}</td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    </table>
                    <script defer>
                        window.print();
                        window.close();
                    </script>
                </body>
            </html>
        `;
        w?.document.write(innerHTML);
    }

    return (
        <>
            <small>Захиалгын дугаар:</small>
            <h3>{trackId}</h3>

            <table className="table table-bordered">
                <tbody>
                    <tr>
                        <td className="table-active">Төлөв:</td>
                        <td>{stats[status]}</td>
                    </tr>
                    <tr>
                        <td className="table-active">Ангилал:</td>
                        <td>{modes[mode]}</td>
                    </tr>
                    <tr>
                        <td className="table-active">Илгээгчийн нэр:</td>
                        <td>{senderName}</td>
                    </tr>
                    <tr>
                        <td className="table-active">Илгээгчийн утас:</td>
                        <td dangerouslySetInnerHTML={{ __html: phoneNumberStyler(senderPhone) }}></td>
                    </tr>
                    <tr>
                        <td colSpan={2} className="table-active">Илгээгчийн хаяг:</td>
                    </tr>
                    <tr>
                        <td colSpan={2}>{senderAddress}</td>
                    </tr>
                    <tr>
                        <td className="table-active">Хүлээн авагчийн нэр:</td>
                        <td>{receiverName}</td>
                    </tr>
                    <tr>
                        <td className="table-active">Хүлээн авагчийн утас:</td>
                        <td dangerouslySetInnerHTML={{ __html: phoneNumberStyler(receiverPhone) }}></td>
                    </tr>
                    <tr>
                        <td colSpan={2} className="table-active">Хүлээн авагчийн хаяг:</td>
                    </tr>
                    <tr>
                        <td colSpan={2}>{receiverAddress}</td>
                    </tr>
                    <tr>
                        <td className="table-active">Жин:</td>
                        <td>{weight}<span className="text-muted">кг</span></td>
                    </tr>
                    <tr>
                        <td className="table-active">Үнийн дүн:</td>
                        <td><span className="text-muted">₩</span>{price}</td>
                    </tr>
                    <tr>
                        <td className="table-active">Төлбөрийн мэдээлэл:</td>
                        <td>{paymentMethods[paymentMethod]}</td>
                    </tr>
                    <tr>
                        <td className="table-active">Төлбөрийн хэрэгсэл:</td>
                        <td>{paymentUtilities[paymentUtility]}</td>
                    </tr>
                    <tr>
                        <td className="table-active">Монголд хүргэлттэй эсэх:</td>
                        <td>{deliveryOptions[hasDelivery]}</td>
                    </tr>
                    <tr>
                        <td className="table-active">Гарах огноо:</td>
                        <td>{departTime}</td>
                    </tr>
                    <tr>
                        <td className="table-active">Буух огноо:</td>
                        <td>{arrivalDate}</td>
                    </tr>
                    <tr>
                        <td className="table-active">Ачааны мэдээлэл:</td>
                        <td>{desc}</td>
                    </tr>
                    <tr>
                        <td className="table-active">Ачааны үнэлгээ:</td>
                        <td>{value}</td>
                    </tr>
                    <tr>
                        <td className="table-active">Ачааны тоо хэмжээ:</td>
                        <td>{quantity}</td>
                    </tr>
                    <tr>
                        <td className="table-active">Шуудангийн төлбөр:</td>
                        <td>{postfee}</td>
                    </tr>
                    <tr>
                        <td className="table-active">Тээврийн мэдээлэл:</td>
                        <td>{notif}</td>
                    </tr>
                    {currentUser?.role === "admin" &&
                        <tr>
                            <td className="table-active">Оруулсан ажилтан:</td>
                            <td>{createdBy}</td>
                        </tr>
                    }
                </tbody>
            </table>

            <object id="template" data="/template.svg" type="image/svg+xml" aria-label="template" style={{height: 0}} ref={template} />

            <Link className="btn btn-light" to="/packages">
                <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-caret-left" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" d="M10 12.796L4.519 8 10 3.204v9.592zm-.659.753l-5.48-4.796a1 1 0 0 1 0-1.506l5.48-4.796A1 1 0 0 1 11 3.204v9.592a1 1 0 0 1-1.659.753z" />
                </svg>&nbsp;
                Буцах
            </Link>

            {["admin", "delivery", "worker"].indexOf(currentUser?.role) !== -1 && <>
                <Link className="btn btn-primary float-right ml-1" to={`/packages/edit/${id}`}>
                    <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-pencil" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5L13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175l-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                    </svg>&nbsp;
                    Шинэчлэх
                </Link>
            </>}

            {["admin", "worker"].indexOf(currentUser?.role) !== -1 && <>
                <button className="btn btn-info float-right ml-1" onClick={printTemplate}>
                    <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-receipt" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" d="M1.92.506a.5.5 0 0 1 .434.14L3 1.293l.646-.647a.5.5 0 0 1 .708 0L5 1.293l.646-.647a.5.5 0 0 1 .708 0L7 1.293l.646-.647a.5.5 0 0 1 .708 0L9 1.293l.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .801.13l.5 1A.5.5 0 0 1 15 2v12a.5.5 0 0 1-.053.224l-.5 1a.5.5 0 0 1-.8.13L13 14.707l-.646.647a.5.5 0 0 1-.708 0L11 14.707l-.646.647a.5.5 0 0 1-.708 0L9 14.707l-.646.647a.5.5 0 0 1-.708 0L7 14.707l-.646.647a.5.5 0 0 1-.708 0L5 14.707l-.646.647a.5.5 0 0 1-.708 0L3 14.707l-.646.647a.5.5 0 0 1-.801-.13l-.5-1A.5.5 0 0 1 1 14V2a.5.5 0 0 1 .053-.224l.5-1a.5.5 0 0 1 .367-.27zm.217 1.338L2 2.118v11.764l.137.274.51-.51a.5.5 0 0 1 .707 0l.646.647.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.509.509.137-.274V2.118l-.137-.274-.51.51a.5.5 0 0 1-.707 0L12 1.707l-.646.647a.5.5 0 0 1-.708 0L10 1.707l-.646.647a.5.5 0 0 1-.708 0L8 1.707l-.646.647a.5.5 0 0 1-.708 0L6 1.707l-.646.647a.5.5 0 0 1-.708 0L4 1.707l-.646.647a.5.5 0 0 1-.708 0l-.509-.51z" />
                        <path fillRule="evenodd" d="M3 4.5a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zm8-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5z" />
                    </svg>&nbsp;
                    Хэвлэх
                </button>
            </>}

            {["admin"].indexOf(currentUser?.role) !== -1 && <>
                <button className="btn btn-danger float-right" onClick={remove}>
                    <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-trash" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                        <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                    </svg>&nbsp;
                    Устгах
                </button>
            </>}
        </>
    );
}

export default View;