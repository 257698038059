import React, { useState } from 'react';
import firebase from "../../firebase";
import ListItem from './ListItem';
import XLSX from 'xlsx';
import { useHistory } from 'react-router-dom';
import { paymentMethods, stats } from '../../enums';

const db = firebase.firestore();

function BatchInsert() {
    const [packs, setPacks] = useState<any[]>([]);
    const [departTime, setDepartTime] = useState("");
    const history = useHistory();

    const onload = (e: any) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const rows: any = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], { header: 1 });
        const ps = [];

        setDepartTime(rows[0][5]);

        for (let i = 2; i < rows.length; i += 1) {
            if (rows[i][2]) {
                ps.push({
                    id: (new Date()).toJSON().split("T")[0].replace(/-/g, "") + Math.random().toString().substr(2, 4),
                    senderName: rows[i][1],
                    senderPhone: rows[i][2],
                    senderAddress: rows[i][3],
                    receiverName: rows[i][4],
                    receiverPhone: rows[i][5],
                    receiverAddress: rows[i][6],
                    weight: rows[i][7],
                    price: rows[i][8],
                    paymentMethod: Object.keys(paymentMethods).find((key) => paymentMethods[key] === rows[i][9]),
                    hasDelivery: !!rows[i][10],
                    departTime: departTime || (new Date()).toJSON().split("T")[0],
                    status: Object.keys(stats)[0]
                });
            }
        }

        setPacks(ps);
    }

    const load = (e: any) => {
        const f = e.target.files[0];
        const reader = new FileReader();
        reader.onload = onload;
        reader.readAsArrayBuffer(f);
    }

    const insert = () => {
        var batch = db.batch();

        packs.forEach((pack) => {
            batch.set(db.doc(`/packages/${pack.id}`), pack);
        });

        batch.commit().then(function () {
            history.push("/packages");
        }).catch(console.error);
    }

    return (
        <>
            <div className="custom-file mb-2">
                <input type="file" className="custom-file-input" id="customFile" onChange={load} />
                <label className="custom-file-label" htmlFor="customFile">Excel файл сонгох</label>
            </div>
            <ul className="list-group mb-2">
                {packs.map((pack) => (
                    <ListItem pack={pack} key={pack.id} />
                ))}
            </ul>
            <button className="btn btn-primary float-right" onClick={insert} disabled={!packs.length}>
                <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-plus-square" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" d="M14 1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                    <path fillRule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                </svg>&nbsp;
                Оруулах
            </button>
            <br />
        </>
    );
}

export default BatchInsert;