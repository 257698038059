import React, { useContext, useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import firebase from "../../firebase";
import { stats, modes, paymentMethods, deliveryOptions, paymentUtilities } from "../../enums";
import Input from "../elements/Input";
import Select from "../elements/Select";
import PhoneInput from "../elements/PhoneInput";
import { AuthContext } from '../AuthProvider';
import Textarea from '../elements/Textarea';

const db = firebase.firestore();

const Create = () => {
    const { currentUser } = useContext(AuthContext);
    const [status, setStatus] = useState<string>(Object.keys(stats)[0]);
    const [senderName, setSenderName] = useState("");
    const [senderPhone, setSenderPhone] = useState("");
    const [senderAddress, setSenderAddress] = useState("");
    const [receiverName, setReceiverName] = useState("");
    const [receiverPhone, setReceiverPhone] = useState("");
    const [receiverAddress, setReceiverAddress] = useState("");
    const [weight, setWeight] = useState(0);
    const [price, setPrice] = useState(0);
    const [paymentMethod, setIsPaymentMethod] = useState(Object.keys(paymentMethods)[0]);
    const [hasDelivery, setHasDelivery] = useState(Object.keys(deliveryOptions)[0]);
    const [mode, setMode] = useState(Object.keys(modes)[0]);
    const [departTime, setDepartTime] = useState((new Date()).toJSON().split("T")[0]);
    const [createdBy, setCreatedBy] = useState(currentUser?.phoneNumber);
    const [desc, setDesc] = useState("");
    const [value, setValue] = useState("");
    const [postfee, setPostfee] = useState(0);
    const [quantity, setQuantity] = useState(0);
    const [notif, setNotif] = useState("");
    const [paymentUtility, setPaymentUtility] = useState(Object.keys(paymentUtilities)[0]);
    const history = useHistory();

    useEffect(() => {
        if (currentUser) {
            setCreatedBy(currentUser.phoneNumber);
        }
    }, [currentUser]);

    const create = () => {
        const id = (new Date()).toJSON().split("T")[0].replace(/-/g, "") + Math.random().toString().substr(2, 4);

        db.doc(`packages/${id}`).set({
            status,
            senderName,
            senderPhone,
            receiverName,
            receiverPhone,
            weight,
            price,
            paymentMethod,
            hasDelivery,
            mode,
            departTime,
            senderAddress,
            receiverAddress,
            createdBy,
            desc,
            value,
            postfee,
            quantity,
            notif,
            paymentUtility,
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        }).then(() => {
            history.push(`/packages/${id}`);
        }).catch(console.error);
    }

    return (
        <>
            <div className="row">
                <div className="col">
                    <Input state={senderName} setState={setSenderName} label="Илгээгчийн нэр" />
                </div>
                <div className="col">
                    <PhoneInput state={senderPhone} setState={setSenderPhone} label="Илгээгчийн утас"/>
                </div>
            </div>
            <Textarea state={senderAddress} setState={setSenderAddress} label="Илгээгчийн хаяг" />
            <div className="row">
                <div className="col">
                    <Input state={receiverName} setState={setReceiverName} label="Хүлээн авагчийн нэр" />
                </div>
                <div className="col">
                    <PhoneInput state={receiverPhone} setState={setReceiverPhone} label="Хүлээн авагчийн утас" defaultCode="+82" />
                </div>
            </div>
            <Textarea state={receiverAddress} setState={setReceiverAddress} label="Хүлээн авагчийн хаяг" />
            <div className="row">
                <div className="col">
                    <Input state={weight} setState={setWeight} label="Жин" type="number" append="кг" />
                </div>
                <div className="col">
                    <Input state={price} setState={setPrice} label="Үнийн дүн" type="number" prepend="₩" />
                </div>
                <div className="col">
                    <Input state={departTime} setState={setDepartTime} label="Гарах огноо" type="date" />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <Select state={paymentMethod} setState={setIsPaymentMethod} label="Төлбөрийн мэдээлэл" options={paymentMethods} />
                </div>
                <div className="col">
                    <Select state={paymentUtility} setState={setPaymentUtility} label="Төлбөрийн хэрэгсэл" options={paymentUtilities} />
                </div>
                <div className="col">
                    <Select state={hasDelivery} setState={setHasDelivery} label="Монголд хүргэлттэй эсэх" options={deliveryOptions} />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <Select state={mode} setState={setMode} label="Ангилал" options={modes} />
                </div>
                <div className="col">
                    <Select state={status} setState={setStatus} label="Төлөв" options={stats} />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <Input state={desc} setState={setDesc} label="Ачааны мэдээлэл" />
                </div>
                <div className="col">
                    <Input state={value} setState={setValue} label="Ачааны үнэлгээ" />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <Input type="number" state={quantity} setState={setQuantity} label="Ачааны тоо хэмжээ" />
                </div>
                <div className="col">
                    <Input type="nubmer" state={postfee} setState={setPostfee} label="Шуудангийн төлбөр" />
                </div>
            </div>
            <Textarea state={notif} setState={setNotif} label="Тээврийн мэдээлэл" />
            <Link className="btn btn-light" to="/packages">
                <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-caret-left" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" d="M10 12.796L4.519 8 10 3.204v9.592zm-.659.753l-5.48-4.796a1 1 0 0 1 0-1.506l5.48-4.796A1 1 0 0 1 11 3.204v9.592a1 1 0 0 1-1.659.753z" />
                </svg>&nbsp;
                Буцах
            </Link>
            <button className="btn btn-primary float-right" onClick={create}>
                <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-plus-square" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" d="M14 1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                    <path fillRule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                </svg>&nbsp;
                Үүсгэх
            </button>
        </>
    );
}

export default Create;